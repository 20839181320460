<template>
	<div>
		<b-modal :id="modalId"
			@close="closeModal"
			no-close-on-backdrop
			no-close-on-esc>
			<template #modal-title>
				Are you sure you want to cancel this booking ?
			</template>
			<div class="py-2">
				<div class="form mb-3" v-if="loadingDates">
					<p>Loading dates</p>
				</div>
				<template v-else>
					<template v-if="isSubscription">
						<template v-if="subscribedTripDays.length">
							<div>
								<p class="m-0 font-sm">Select dates to cancel</p>
								<div class="items-wrapper">
									<div v-for="(date, index) in subscribedTripDays"
										:key="index" class="item" :class="date.selected ? 'item__selected' : ''" @click="updateSelection(date)">
										<span>{{date.date}}</span>
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<p class="mx-auto text-center">Unable to load dates for subscription booking</p>
						</template>
					</template>
					<div v-else>
						<p>Click "Confirm" to cancel booking</p>
					</div>

				</template>
			</div>
			<template #modal-footer>
				<div class="w-100">
					<b-button
						v-if="(isSubscription ? subscribedTripDays.length : true)"
						:disabled="loadingDates || cancellingTrip"
						variant="primary"
						class="float-right"
						@click="cancelBooking()">
						{{ cancellingTrip ? 'Processing' : 'Confirm' }}
					</b-button>
					<b-button
						:disabled="cancellingTrip"
						variant="secondary"
						class="float-right mr-2"
						@click="closeModal()">
						Close
					</b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import { extractErrorMessage } from '@/utils/helpers'

export default {
  name: 'DeleteUserBookingModal',
  props: {
    show: Boolean,
    booking: Object
  },
  data () {
    return {
      cancellingTrip: false,
      subscribedTripDays: [],
      modalId: 'delete-booking-modal',
      loadingDates: false
    }
  },
  watch: {
    show: {
      handler: function (value) {
        if (value) {
          this.openModal()
        } else {
          this.closeModal()
        }
      }
    }
  },
  computed: {
    isSubscription () {
      return !!this.booking.recurring
    }
  },
  methods: {
    resetView () {
      this.cancellingTrip = false
      this.loadingDates = false
      this.subscribedTripDays = []
    },
    openModal () {
      this.$bvModal.show(this.modalId)
      if (this.isSubscription) {
        this.fetchSubscriptionDates(this.booking)
      }
    },
    closeModal () {
      this.$bvModal.hide(this.modalId)
      this.$emit('close')
      this.resetView()
    },
    fetchSubscriptionDates (booking) {
      this.loadingDates = true
      this.axios.get(`/v1/users/routes/user_route_id/${booking.id}`)
        .then((res) => {
          if (res.status === 200) {
            this.subscribedTripDays = res.data.map((x) => ({ date: x.trip_date, selected: false }))
          }
        })
        .catch((error) => {
          if (error.response.data && error.response.data.message) {
            return this.$toastr.e(error.response.data.message)
          }
          return this.$toastr.e('An error occurred while cancelling booking, please try again later')
        })
        .finally(() => {
          this.loadingDates = false
        })
    },
    cancelBooking () {
      const { data, request } = this.validateAndCreateRequest()
      if (request) {
        this.cancellingTrip = true
        request
          .then(() => {
            this.closeModal()
            this.$emit('trip-cancelled', this.isSubscription ? this.subscribedTripDays.length === data?.booking_days?.length : true)
          })
          .catch((error) => {
            const msg = extractErrorMessage(error, 'An error occurred please try again later.')
            this.$swal('Failed to cancel booking', msg, 'error')
          }).finally(() => {
            this.cancellingTrip = false
            this.showDeleteModal = false
          })
      }
    },

    validateAndCreateRequest () {
      const { itinerary_id, route_id, pickup_id, destination_id, user_id, id: user_route_id } = this.booking
      const booked_days = this.subscribedTripDays.map((o) =>{
        if (o.selected) {
          return o.date
        }
      })


      let payload = { itinerary_id, route_id, pickup_id, destination_id, user_id, user_route_id, booked_days }
      if (this.isSubscription) {
        const dates = this.subscribedTripDays.filter((o) => o.selected).map((o) => o.date)
        if (dates?.length) {
          payload = { ...payload, booking_days: dates }
        } else {
          this.$toastr.e('Please select trip days to cancel')
          return null
        }
      }
      return { data: payload, request: this.axios.delete(`/v1/users/${user_id}/routes`, { data: payload }) }
    },
    updateSelection (date) {
      date.selected = !date.selected
    }
  }
}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/partials/selector";
</style>
